import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import {
  getAllAdminUser,
  activateDeactivateAdmin,
  deactivateUser,
} from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import Pagination from "../../components/Pagination/Pagination";
import AddEdit from "./AddEdit";
import ChangePassword from "./ChangePassword";

const Admins = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalUser, setTotalUser] = useState(null);
  const { pathname, search } = useLocation();
  const rowsPerPage = 10;
  const navigate = useNavigate();

  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getAllAdminUser(page, rowsPerPage);
      console.log(apiResponse.data.data.data);

      if (apiResponse) {
        setData(apiResponse.data?.data?.data);

        setTotalUser(apiResponse.data.data.totalUsers);
        setCurrentPage(apiResponse.data.data.currentPage);
        setPage(apiResponse?.data?.data?.currentPage);
      } else {
        setData([]);
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [pathname, search, stopLoadingRequest, logoutRequest, page]);
  useEffect(() => {
    fetch(page, rowsPerPage);
  }, []);
  useEffect(() => {
    startLoadingRequest();
    fetch(page, rowsPerPage);
  }, [pathname, search, startLoadingRequest, fetch, page]);

  const activateDeactivateAdminHandler = useCallback(
    async (id, requestType) => {
      try {
        startLoadingRequest();
        await deactivateUser({ userId: id }, { requestType });
        fetch(page, rowsPerPage);
        toast.success(
          requestType === 1
            ? "Admin activated successfully"
            : "Admin deactivated successfully"
        );
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          logoutRequest();
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
        }
        stopLoadingRequest();
      }
    },
    [fetch, startLoadingRequest, stopLoadingRequest, logoutRequest]
  );
  const handlePageChange = (newPage) => {
    navigate(`/admins?page=${newPage}`); // Update the URL when the page changes
  };
  return (
    <div className="section">
      <h1>Admins</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
            <AddEdit fetch={fetch} />
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Gender</th>
                  <th>Status</th>
                  <th>Role</th>
                  <th>Last Updated At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data?.map((item, index) => (
                    <tr key={item._id}>
                      <td>{index + 1}</td>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td>{item.email}</td>
                      <td>{item.gender}</td>
                      <td>{item.isActive ? "Active" : "Inactive"}</td>
                      <td>{item?.roleId?.name || "No Role Assigned"}</td>
                      <td>
                        {item.updatedAt
                          ? new Date(item.updatedAt).toLocaleString()
                          : "N/A"}
                      </td>
                      <td>
                        <div className="table-actions">
                          {item?.isActive ? (
                            <>
                              <AddEdit data={item} fetch={fetch} />
                              {/* <ChangePassword data={item} fetch={fetch} /> */}
                              <button
                                className="danger"
                                onClick={() =>
                                  activateDeactivateAdminHandler(item._id, 0)
                                }
                              >
                                Delete
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() =>
                                activateDeactivateAdminHandler(item._id, 1)
                              }
                              className="success"
                            >
                              Activate
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9" align="center">
                      No admins found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {data?.length > 0 && (
            <Pagination
              pageInfo={{
                page: currentPage,
                total: total,
                totaluser: totalUser,
                rowsPerPage: rowsPerPage,
              }}
              // onPageChange={() => setPage((prev) => prev + 1)}
              onPageChange={handlePageChange} // Handle page change
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Admins);
