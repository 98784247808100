// // import React, { useState, useEffect } from "react";
// // import { connect } from "react-redux";
// // import { bindActionCreators } from "redux";
// // import actions from "../../actions";
// // import styles from "../Chat/Chat.module.css";
// // import { io } from "socket.io-client";
// // import { getSupportUser } from "../../apis";

// // const HelpCenter = ({ startLoadingRequest, stopLoadingRequest }) => {
// //   const [users, setUsers] = useState([]);
// //   const [message, setMessage] = useState("");
// //   const [messages, setMessages] = useState([]); // Ensure this is an array
// //   const [selectUser, setSelectUser] = useState(null);
// //   const [media, setMedia] = useState([]);
// //   const [selectedUser, setSelectedUser] = useState(null);
// //   const [socket, setSocket] = useState(null);
// //   const [chats, setChats] = useState([]);
// //   const [userid, setUserid] = useState(null);

// //   useEffect(() => {
// //     stopLoadingRequest();

// //     // Fetch user details from localStorage once and set userid
// //     const userDetails = localStorage.getItem("userDetails");
// //     if (userDetails) {
// //       const user_id = JSON.parse(userDetails)._id;
// //       setUserid(user_id);
// //     }
// //   }, []);

// //   const handleUserClick = (user) => {
// //     setSelectedUser(user);
// //     // Fetch chat data for the selected user and update the chats state
// //   };

// //   const handleSendMessage = () => {
// //     console.log("hit send", selectUser);
// //     console.log(message);
// //     const userDetails = localStorage.getItem("userDetails");

// //     if (userDetails) {
// //       console.log(userDetails, "11111");
// //       const user_id = JSON.parse(userDetails)._id;
// //       const senderId = user_id;
// //       const receiverId = selectUser.userId._id;
// //       const roomId = selectUser.roomId;

// //       socket.emit(
// //         "support_send_message",
// //         message,
// //         senderId,
// //         receiverId,
// //         roomId,
// //         media
// //       );
// //       setMessage("");
// //     }
// //   };

// //   const loadChat = (user) => {
// //     const userDetails = localStorage.getItem("userDetails");
// //     const user_id = JSON.parse(userDetails);
// //     const senderId = user_id?._id;
// //     const receiverId = user?.userId?._id;
// //     if (socket) {
// //       socket.emit("load_chat_support", senderId, receiverId);
// //     }
// //   };

// //   useEffect(() => {
// //     const fetchUser = async () => {
// //       const response = await getSupportUser();
// //       console.log(response);
// //       setUsers(response?.data?.data || []); // Fallback to empty array if no users
// //     };
// //     fetchUser();
// //   }, []);

// //   useEffect(() => {
// //     const newSocket = io("https://6c61-45-118-166-186.ngrok-free.app/", {
// //       extraHeaders: { "ngrok-skip-browser-warning": "any-value" },
// //     });
// //     newSocket.on("connect", () => {
// //       setSocket(newSocket);
// //     });
// //     return () => {
// //       if (newSocket) {
// //         newSocket.disconnect();
// //       }
// //     };
// //   }, []);

// //   useEffect(() => {
// //     const userDetails = localStorage.getItem("userDetails");
// //     const user_id = JSON.parse(userDetails);

// //     if (!socket) return;

// //     const handleAdminMessageReceived = (item) => {
// //       console.log(item, "35");
// //       setMessages((prevMessages) => [...prevMessages, item]);
// //     };

// //     const handleLoadAdminChat = (item) => {
// //       console.log(item);
// //       // Ensure the response is an array
// //       setMessages(Array.isArray(item) ? item : []); // Ensure messages is an array
// //     };
// //     socket.on("support_send_message_received", handleAdminMessageReceived);
// //     socket.on("support_message_received", handleLoadAdminChat);

// //     return () => {
// //       socket.off("message_received", handleAdminMessageReceived);
// //       socket.off("loadAdminChat", handleLoadAdminChat);
// //     };
// //   }, [socket]);

// //   const renderOptions = (options) => {
// //     return (
// //       <div className={styles.optionsContainer}>
// //         {options.map((option, index) => (
// //           <button key={index} className={styles.optionButton}>
// //             {option}
// //           </button>
// //         ))}
// //       </div>
// //     );
// //   };

// //   return (
// //     <div className="section chatContainer">
// //       <div className={styles.userList}>
// //         {users?.map((user, index) => (
// //           <div
// //             key={index}
// //             className={styles.userItem}
// //             onClick={() => {
// //               console.log(user);
// //               setSelectUser(user);
// //               loadChat(user);
// //             }}
// //           >
// //             {user?.userName || "No name available"}
// //           </div>
// //         ))}
// //       </div>
// //       <div className={styles.chatWindow}>
// //         {selectUser ? (
// //           <>
// //             <div className={styles.chatHeader}>
// //               Chat with {selectUser?.userId?.firstName || "User"}
// //             </div>
// //             <div className={styles.chatMessages}>
// //               {/* Fallback to empty array if messages is not an array */}
// //               {Array.isArray(messages) && messages.length > 0 ? (
// //                 messages.map((chat) => (
// //                   <div key={chat._id}>
// //                     <div
// //                       className={
// //                         chat.senderId === userid
// //                           ? styles.adminMessage
// //                           : styles.userMessage
// //                       }
// //                     >
// //                       {chat.message}
// //                       {chat.senderId === userid &&
// //                         chat.options &&
// //                         chat.options.length > 0 &&
// //                         renderOptions(chat.options)}
// //                     </div>
// //                   </div>
// //                 ))
// //               ) : (
// //                 <div>No messages available</div>
// //               )}
// //             </div>
// //             <div className={styles.chatInput}>
// //               <input
// //                 onChange={(e) => {
// //                   setMessage(e.target.value);
// //                 }}
// //                 value={message}
// //                 type="text"
// //                 placeholder="Type a message..."
// //               />
// //               <button onClick={handleSendMessage}>Send</button>
// //             </div>
// //           </>
// //         ) : (
// //           <div className={styles.noChatSelected}>
// //             Select a user to start chatting
// //           </div>
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // const mapDispatchToProps = (dispatch) =>
// //   bindActionCreators(
// //     {
// //       logoutRequest: actions.auth.logout,
// //       startLoadingRequest: actions.loader.startLoading,
// //       stopLoadingRequest: actions.loader.stopLoading,
// //     },
// //     dispatch
// //   );

// // export default connect(null, mapDispatchToProps)(HelpCenter);

// import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import actions from "../../actions";
// import styles from "../Chat/Chat.module.css";
// import { io } from "socket.io-client";
// import { getSupportUser } from "../../apis";

// const HelpCenter = ({ startLoadingRequest, stopLoadingRequest }) => {
//   const [users, setUsers] = useState([]);
//   const [message, setMessage] = useState("");
//   const [messages, setMessages] = useState([]); // Ensure this is an array
//   const [selectUser, setSelectUser] = useState(null);
//   const [media, setMedia] = useState([]);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [socket, setSocket] = useState(null);
//   const [chats, setChats] = useState([]);
//   const [userid, setUserid] = useState(null);

//   useEffect(() => {
//     stopLoadingRequest();

//     // Fetch user details from localStorage once and set userid
//     const userDetails = localStorage.getItem("userDetails");
//     if (userDetails) {
//       const user_id = JSON.parse(userDetails)._id;
//       setUserid(user_id);
//     }
//   }, []);

//   const handleUserClick = (user) => {
//     console.log(user);

//     setSelectedUser(user);
//     loadChat(user);
//   };

//   const handleSendMessage = () => {
//     console.log("hit send", selectUser);
//     console.log(message);
//     const userDetails = localStorage.getItem("userDetails");

//     if (userDetails) {
//       const user_id = JSON.parse(userDetails)._id;
//       const senderId = user_id;
//       const receiverId = selectUser.userId._id;
//       const roomId = selectUser.roomId;

//       // Emit the message to the server
//       socket.emit(
//         "support_send_message",
//         message,
//         senderId,
//         receiverId,
//         roomId,
//         media
//       );

//       // Clear the message input after sending
//       setMessage("");
//     }
//   };

//   const loadChat = (user) => {
//     const userDetails = localStorage.getItem("userDetails");
//     const user_id = JSON.parse(userDetails);
//     const senderId = user_id?._id;
//     const receiverId = user?.userId;
//     if (socket) {
//       socket.emit("load_chat_support", senderId, receiverId);
//     }
//   };

//   useEffect(() => {
//     const fetchUser = async () => {
//       const response = await getSupportUser();
//       setUsers(response?.data?.data || []);
//     };
//     fetchUser();
//   }, []);

//   useEffect(() => {
//     const newSocket = io("https://6c61-45-118-166-186.ngrok-free.app/", {
//       extraHeaders: { "ngrok-skip-browser-warning": "any-value" },
//     });
//     newSocket.on("connect", () => {
//       setSocket(newSocket);
//     });
//     return () => {
//       if (newSocket) {
//         newSocket.disconnect();
//       }
//     };
//   }, []);

//   useEffect(() => {
//     const userDetails = localStorage.getItem("userDetails");
//     const user_id = JSON.parse(userDetails);

//     if (!socket) return;

//     // Handle receiving a message
//     const handleAdminMessageReceived = (message) => {
//       console.log("Received message:", message);
//       setMessages((prevMessages) => [...prevMessages, message]);
//     };

//     // Handle loading chat history for the support user
//     const handleLoadChatSupport = (chatData) => {
//       console.log("Loaded chat data:", chatData);
//       setMessages(Array.isArray(chatData) ? chatData : []); // Ensure the chatData is an array
//     };

//     // Listen to the events
//     socket.on("support_message_received", handleAdminMessageReceived);
//     socket.on("load_chat_support", handleLoadChatSupport);

//     return () => {
//       // Clean up the event listeners on component unmount
//       socket.off("support_message_received", handleAdminMessageReceived);
//       socket.off("load_chat_support", handleLoadChatSupport);
//     };
//   }, [socket]);

//   const renderOptions = (options) => {
//     return (
//       <div className={styles.optionsContainer}>
//         {options.map((option, index) => (
//           <button key={index} className={styles.optionButton}>
//             {option}
//           </button>
//         ))}
//       </div>
//     );
//   };

//   return (
//     <div className="section chatContainer">
//       <div className={styles.userList}>
//         {users?.map((user, index) => (
//           <div
//             key={index}
//             className={styles.userItem}
//             onClick={() => handleUserClick(user)}
//           >
//             {user?.userName || "No name available"}
//           </div>
//         ))}
//       </div>
//       <div className={styles.chatWindow}>
//         {selectUser ? (
//           <>
//             <div className={styles.chatHeader}>
//               Chat with {selectUser?.userId?.firstName || "User"}
//             </div>
//             <div className={styles.chatMessages}>
//               {Array.isArray(messages) && messages.length > 0 ? (
//                 messages.map((chat) => (
//                   <div key={chat._id}>
//                     <div
//                       className={
//                         chat.senderId === userid
//                           ? styles.adminMessage
//                           : styles.userMessage
//                       }
//                     >
//                       {console.log(chat)}
//                       {chat.message}
//                       {chat.senderId === userid &&
//                         chat.options &&
//                         chat.options.length > 0 &&
//                         renderOptions(chat.options)}
//                     </div>
//                   </div>
//                 ))
//               ) : (
//                 <div>No messages available</div>
//               )}
//             </div>
//             <div className={styles.chatInput}>
//               <input
//                 onChange={(e) => setMessage(e.target.value)}
//                 value={message}
//                 type="text"
//                 placeholder="Type a message..."
//               />
//               <button onClick={handleSendMessage}>Send</button>
//             </div>
//           </>
//         ) : (
//           <div className={styles.noChatSelected}>
//             Select a user to start chatting
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       logoutRequest: actions.auth.logout,
//       startLoadingRequest: actions.loader.startLoading,
//       stopLoadingRequest: actions.loader.stopLoading,
//     },
//     dispatch
//   );

// export default connect(null, mapDispatchToProps)(HelpCenter);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../../actions";
import styles from "../Chat/Chat.module.css";
import { io } from "socket.io-client";
import { getSupportUser } from "../../apis";

const HelpCenter = ({ startLoadingRequest, stopLoadingRequest }) => {
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]); // Ensure this is an array
  const [selectUser, setSelectUser] = useState(null);
  const [userid, setUserid] = useState(null); // Current user ID
  const [socket, setSocket] = useState(null);

  // Load user details from local storage and set the user ID
  useEffect(() => {
    stopLoadingRequest();
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      const user_id = JSON.parse(userDetails)._id;
      setUserid(user_id);
    }
  }, []);

  // Handle user click and load the chat for that user
  const handleUserClick = (user) => {
    console.log(user);
    setSelectUser(user);
    loadChat(user);
  };

  // Handle sending a message
  const handleSendMessage = () => {
    console.log(selectUser);
    if (message && selectUser && socket) {
      const senderId = "admin";
      const receiverId = selectUser.userId;
      const roomId = selectUser.roomIds;

      // Emit the message to the server
      socket.emit(
        "support_send_message",
        message,
        senderId,
        receiverId,
        roomId
      );

      // Clear the message input after sending
      setMessage("");
    }
  };

  // Load chat messages for the selected user
  const loadChat = (user) => {
    if (socket) {
      const senderId = "admin";
      const receiverId = user.userId;
      socket.emit("load_chat_support", senderId, receiverId);
    }
  };

  // Fetch support users
  useEffect(() => {
    const fetchUser = async () => {
      const response = await getSupportUser();
      setUsers(response?.data?.data || []); // Ensure users is an array
    };
    fetchUser();
  }, []);

  // Initialize socket connection
  useEffect(() => {
    const newSocket = io("wss://ponderiee-api.applore.in/", {
      extraHeaders: { "ngrok-skip-browser-warning": "any-value" },
    });
    newSocket.on("connect", () => {
      setSocket(newSocket);
    });
    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, []);

  // Listen for incoming messages and load chat history
  useEffect(() => {
    if (!socket) return;

    const handleAdminMessageReceived = (message) => {
      setMessages((prevMessages) => [...prevMessages, message]); // Append new messages
    };

    const handleLoadChatSupport = (chatData) => {
      setMessages(Array.isArray(chatData) ? chatData : []); // Ensure it's an array
    };

    socket.on("support_message_received", handleAdminMessageReceived);
    // socket.emit("load_chat_support", senderId, receiverId);

    socket.on("load_chat_support", handleLoadChatSupport);

    return () => {
      socket.off("support_message_received", handleAdminMessageReceived);
      socket.off("load_chat_support", handleLoadChatSupport);
    };
  }, [socket]);

  // Render chat options
  const renderOptions = (options) => {
    return (
      <div className={styles.optionsContainer}>
        {options.map((option, index) => (
          <button key={index} className={styles.optionButton}>
            {option}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="section chatContainer">
      <div className={styles.userList}>
        {users?.map((user, index) => (
          <div
            key={index}
            className={styles.userItem}
            onClick={() => handleUserClick(user)}
          >
            {user?.userName || "No name available"}
          </div>
        ))}
      </div>
      <div className={styles.chatWindow}>
        {selectUser ? (
          <>
            <div className={styles.chatHeader}>
              Chat with {selectUser?.userName || "User"}
            </div>
            <div className={styles.chatMessages}>
              {Array.isArray(messages) && messages.length > 0 ? (
                messages.map((chat) => (
                  <div key={chat._id}>
                    <div
                      className={
                        chat.senderId === userid
                          ? styles.adminMessage
                          : styles.userMessage
                      }
                    >
                      {chat.message}
                      {chat.senderId === userid &&
                        chat.options &&
                        chat.options.length > 0 &&
                        renderOptions(chat.options)}
                    </div>
                  </div>
                ))
              ) : (
                <div>No messages available</div>
              )}
            </div>
            <div className={styles.chatInput}>
              <input
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                type="text"
                placeholder="Type a message..."
              />
              <button onClick={handleSendMessage}>Send</button>
            </div>
          </>
        ) : (
          <div className={styles.noChatSelected}>
            Select a user to start chatting
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(HelpCenter);
