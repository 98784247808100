import React, { useCallback, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import SelectField from "../../components/Select/Select";
import { createBanner, updateBanner, uploadSingleDocument } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";

const AddEdit = ({
  fetch,
  data,
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
  type,
}) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    docId: data?.document?._id || "",
    title: data?.title || "",
    caption: data?.caption || "",
    type: { value: data?.type || "", label: data?.type || "" },
    image: data?.document?.url || "",
    file: "",
  });

  const [errors, setErrors] = useState({
    docId: "",
    title: "",
    caption: "",
    type: "",
  });

  const openHandler = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );
  const selectDropdownChangeHandler = useCallback(
    (name, value) => {
      setFormData({
        ...formData,
        [name]: value,
      });
    },
    [formData]
  );

  const fileUploadHandler = useCallback(async () => {
    if (typeof formData?.file === "object") {
      const form = new FormData();
      form.append("file", formData.file);
      const apiResponse = await uploadSingleDocument(form);
      return apiResponse?.data?.data?.documentId;
    } else {
      return formData?.docId || "";
    }
  }, [formData]);

  const submitHandler = useCallback(async () => {
    try {
      startLoadingRequest();
      const docId = await fileUploadHandler();
      let cleanData = {
        title: formData?.title?.trim(),
        caption: formData?.caption?.trim(),
        docId: docId || formData?.docId?.trim(),
        type: formData?.type?.value?.trim(),
      };
      let tempErrors = {};
      if (!cleanData.type) {
        tempErrors["type"] = "Type is required";
      }
      if (!cleanData.docId) {
        tempErrors["docId"] = "Image is required";
      }
      if (!cleanData.caption) {
        tempErrors["caption"] = "Caption is required";
      }
      if (!cleanData.title) {
        tempErrors["title"] = "Title is required";
      }
      if (Object.keys(tempErrors)?.length > 0) {
        setErrors(tempErrors);
        stopLoadingRequest();
        return;
      } else {
        setErrors({});
      }
      if (data?._id) {
        await updateBanner(data?._id, cleanData);
      } else {
        await createBanner(cleanData);
      }
      setOpen(false);
      setFormData({
        docId: data?.document?._id || "",
        title: data?.title || "",
        caption: data?.caption || "",
        type: { value: data?.type || "", label: data?.type || "" },
        image: data?.document?.url || "",
        file: "",
      });
      setErrors({
        docId: "",
        title: "",
        caption: "",
        type: "",
        image: "",
        file: "",
      });
      fetch();
    } catch (error) {
      stopLoadingRequest();
      toast.error(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        logoutRequest();
      }
    }
  }, [formData, data]);

  useEffect(() => {
    if (data?._id) {
      setFormData({
        docId: data?.document?._id || "",
        title: data?.title || "",
        caption: data?.caption || "",
        type: { value: data?.type || "", label: data?.type || "" },
        image: data?.document?.url || "",
        file: "",
      });
    } else {
      setFormData({
        docId: "",
        title: "",
        caption: "",
        type: { value: "", label: "" },
        image: "",
        file: "",
      });
    }
  }, [data?._id]);

  const fileHandler = useCallback(
    (event) => {
      if (event?.target?.files[0]) {
        setFormData({
          ...formData,
          file: event.target.files[0],
          image: URL.createObjectURL(event.target.files[0]),
        });
      }
    },
    [formData]
  );
  console.log(formData.type);
  return (
    <div className="modal-container">
      <div className="add-modal">
        <button
          className={data?._id ? "secondary" : "add"}
          onClick={openHandler}
        >
          {data?._id ? "Edit" : "Add"}
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>{data?._id ? "Edit Admin" : "Add Admin"}</h2>
            <button type="button" className="close" onClick={openHandler}>
              <IoClose size={18} />
            </button>
          </div>
          <div className="content">
            <div className="form-section">
              <div className="input-wrapper-image">
                <div className="image">
                  {formData.image && <img src={formData.image} />}
                </div>
                <label htmlFor={type}>
                  Upload Image{" "}
                  {formData.type?.value === "Smart mirror(Home)"
                    ? "(Aspect ratio - 16:9)"
                    : "(Aspect ratio - 3:2)"}
                </label>
                <input
                  onChange={fileHandler}
                  accept="Image/*"
                  id={type}
                  type="file"
                />
                {errors.docId && <p>{errors.docId}</p>}
              </div>
              <div className="input-wrapper">
                <label>Title</label>
                <input
                  name="title"
                  value={formData.title}
                  onChange={changeHandler}
                />
                {errors.title && <p>{errors.title}</p>}
              </div>
              <div className="input-wrapper">
                <label>Caption</label>
                <input
                  name="caption"
                  value={formData.caption}
                  onChange={changeHandler}
                />
                {errors.caption && <p>{errors.caption}</p>}
              </div>

              <div className="input-wrapper">
                <label>Type</label>
                <SelectField
                  name="type"
                  onChange={selectDropdownChangeHandler}
                  value={formData.type}
                  isMulti={false}
                  options={[
                    {
                      value: "Smart mirror(Home)",
                      label: "Smart mirror(Home)",
                    },
                    {
                      value: "Salons",
                      label: "Salons",
                    },
                    {
                      value: "Products",
                      label: "Products",
                    },
                    {
                      value: "Social",
                      label: "Social",
                    },
                    {
                      value: "Loyalty",
                      label: "Loyalty",
                    },
                  ]}
                />
                {errors.type && <p>{errors.type}</p>}
              </div>
            </div>
          </div>
          <div className="footer">
            <button type="button" onClick={submitHandler} className="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddEdit);
