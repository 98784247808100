// import React, { useEffect, useState, useCallback } from "react";
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import SelectField from "../../components/Select/Select";
// import actions from "../../actions";
// import {
//   updateAdminDetails,
//   uploadSingleDocument,
//   updateAdminImage,
//   getAdminDetails,
// } from "../../apis";
// import { toast } from "react-toastify";
// import "./accountSettings.css";

// const AccountSettings = ({
//   adminDetails,
//   logoutRequest,
//   startLoadingRequest,
//   stopLoadingRequest,
//   userDetailsRequest,
// }) => {
//   const [data, setData] = useState({
//     firstName: adminDetails?.firstName || "",
//     lastName: adminDetails?.lastName || "",
//     gender: { value: adminDetails?.gender, label: adminDetails?.gender } || {
//       value: "",
//       label: "",
//     },
//   });

//   const [errors, setErrors] = useState({
//     firstName: "",
//     lastName: "",
//     gender: "",
//   });

//   const selectDropdownChangeHandler = useCallback(
//     (name, value) => {
//       setData({
//         ...data,
//         [name]: value,
//       });
//     },
//     [data]
//   );

//   const changeHandler = useCallback(
//     (event) => {
//       setData({
//         ...data,
//         [event.target.name]: event.target.value,
//       });
//     },
//     [data]
//   );

//   useEffect(() => {
//     setData({
//       firstName: adminDetails?.firstName || "",
//       lastName: adminDetails?.lastName || "",
//       gender:
//         { value: adminDetails?.gender, label: adminDetails?.gender } || "",
//     });
//   }, [adminDetails]);

//   const submitHandler = useCallback(async () => {
//     try {
//       startLoadingRequest();
//       let cleanData = {
//         firstName: data?.firstName?.trim(),
//         lastName: data?.lastName?.trim(),
//         gender: data?.gender?.value,
//       };
//       let tempErrors = {};
//       if (!cleanData.firstName) {
//         tempErrors["firstName"] = "First name is required";
//       }
//       if (!cleanData.lastName) {
//         tempErrors["lastName"] = "Last name is required";
//       }
//       if (!cleanData.gender) {
//         tempErrors["gender"] = "Gender is required";
//       }
//       if (Object.keys(tempErrors)?.length > 0) {
//         setErrors(tempErrors);
//         stopLoadingRequest();
//         return;
//       } else {
//         setErrors({});
//       }

//       const apiResponse = await updateAdminDetails(cleanData);
//       if (apiResponse?.data?.data?.adminDetails) {
//         userDetailsRequest(apiResponse?.data?.data?.adminDetails);
//       }

//       setErrors({
//         firstName: "",
//         lastName: "",
//         gender: "",
//       });
//       stopLoadingRequest();
//       toast.success("Profile updated successfulty");
//     } catch (error) {
//       stopLoadingRequest();
//       toast.error(error?.response?.data?.message);
//       if (error?.response?.status === 401) {
//         logoutRequest();
//       }
//     }
//   }, [data]);

//   const fileUploadHandler = useCallback(async (file) => {
//     const form = new FormData();
//     form.append("file", file);
//     const apiResponse = await uploadSingleDocument(form);
//     return apiResponse?.data?.data?.documentId;
//   }, []);

//   const uploadImageHandler = useCallback(async (event) => {
//     try {
//       if (event?.target?.files[0]) {
//         const docId = await fileUploadHandler(event?.target?.files[0]);
//         if (docId) {
//           await updateAdminImage({ docId });
//           const apiResponse = await getAdminDetails();
//           if (apiResponse?.data?.data?.adminDetails) {
//             userDetailsRequest(apiResponse?.data?.data?.adminDetails);
//           }
//         }
//       }
//     } catch (error) {
//       toast.error(error?.response?.data?.message);
//       if (error?.response?.status === 401) {
//         logoutRequest();
//       }
//     }
//   }, []);

//   return (
//     <div className="section">
//       <div className="settings-wrapper">
//         {/* <div className="wrapper image">
//           <input
//             onChange={uploadImageHandler}
//             accept="Image/*"
//             id="image"
//             type="file"
//           />
//           <label htmlFor="image">
//             <div>{adminDetails?.image && <img src={adminDetails.image} />}</div>
//           </label>
//         </div> */}
//         <div className="wrapper inputs">
//           <div className="input-wrapper">
//             <label>First Name</label>
//             <input
//               onChange={changeHandler}
//               name="firstName"
//               value={data.firstName}
//             />
//             {errors.firstName && <p>{errors.firstName}</p>}
//           </div>
//           <div className="input-wrapper">
//             <label>Last Name</label>
//             <input
//               onChange={changeHandler}
//               name="lastName"
//               value={data.lastName}
//             />
//             {errors.lastName && <p>{errors.lastName}</p>}
//           </div>
//           <div className="input-wrapper">
//             <label>Gender</label>
//             <SelectField
//               name="gender"
//               onChange={selectDropdownChangeHandler}
//               value={data.gender}
//               isMulti={false}
//               options={[
//                 {
//                   value: "Male",
//                   label: "Male",
//                 },
//                 {
//                   value: "Female",
//                   label: "Female",
//                 },
//                 {
//                   value: "Not Specified",
//                   label: "Not Specified",
//                 },
//               ]}
//             />
//             {errors.lastName && <p>{errors.gender}</p>}
//           </div>
//           <button onClick={submitHandler} className="primary">
//             Submit
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// const mapStateToProps = (state) => ({
//   adminDetails: state.auth.data,
// });

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       logoutRequest: actions.auth.logout,
//       stopLoadingRequest: actions.loader.stopLoading,
//       startLoadingRequest: actions.loader.startLoading,
//       userDetailsRequest: actions.auth.userDetails,
//     },
//     dispatch
//   );

// export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);

import React, { useEffect, useState, useCallback } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SelectField from "../../components/Select/Select";
import actions from "../../actions";
import {
  updateAdminUser,
  uploadSingleDocument,
  getAdminDetails,
} from "../../apis";
import { toast } from "react-toastify";
import "./accountSettings.css";

const AccountSettings = ({
  adminDetails,
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
  userDetailsRequest,
}) => {
  const [data, setData] = useState({
    firstName: adminDetails?.firstName || "",
    lastName: adminDetails?.lastName || "",
    gender: { value: adminDetails?.gender, label: adminDetails?.gender } || {
      value: "",
      label: "",
    },
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    gender: "",
  });

  const selectDropdownChangeHandler = useCallback(
    (name, value) => {
      setData({
        ...data,
        [name]: value,
      });
    },
    [data]
  );

  const changeHandler = useCallback(
    (event) => {
      setData({
        ...data,
        [event.target.name]: event.target.value,
      });
    },
    [data]
  );

  useEffect(() => {
    setData({
      firstName: adminDetails?.firstName || "",
      lastName: adminDetails?.lastName || "",
      gender:
        { value: adminDetails?.gender, label: adminDetails?.gender } || "",
    });
  }, [adminDetails]);

  const submitHandler = useCallback(async () => {
    try {
      startLoadingRequest();

      // Clean and prepare data
      let cleanData = {
        firstName: data?.firstName?.trim(),
        lastName: data?.lastName?.trim(),
        gender: data?.gender?.value,
      };

      // Validation
      let tempErrors = {};
      if (!cleanData.firstName) {
        tempErrors["firstName"] = "First name is required";
      }
      if (!cleanData.lastName) {
        tempErrors["lastName"] = "Last name is required";
      }
      if (!cleanData.gender) {
        tempErrors["gender"] = "Gender is required";
      }
      if (Object.keys(tempErrors)?.length > 0) {
        setErrors(tempErrors);
        stopLoadingRequest();
        return;
      } else {
        setErrors({});
      }

      // Prepare API data
      const apiData = {
        firstName: cleanData.firstName,
        lastName: cleanData.lastName,
        gender: cleanData.gender,
        userId: adminDetails?._id, // Ensure you have the userId
      };

      // API call to update admin user
      const apiResponse = await updateAdminUser(apiData);

      if (apiResponse?.data?.data?.adminDetails) {
        userDetailsRequest(apiResponse?.data?.data?.adminDetails);
        toast.success("Profile updated successfully");
      } else {
        throw new Error("Failed to update profile");
      }

      setErrors({
        firstName: "",
        lastName: "",
        gender: "",
      });
      stopLoadingRequest();
    } catch (error) {
      stopLoadingRequest();
      toast.error(error?.response?.data?.message || "Something went wrong");
      if (error?.response?.status === 401) {
        logoutRequest();
      }
    }
  }, [
    data,
    adminDetails,
    startLoadingRequest,
    stopLoadingRequest,
    userDetailsRequest,
    logoutRequest,
  ]);

  return (
    <div className="section">
      <div className="settings-wrapper">
        <div className="wrapper inputs">
          <div className="input-wrapper">
            <label>First Name</label>
            <input
              onChange={changeHandler}
              name="firstName"
              value={data.firstName}
            />
            {errors.firstName && (
              <p className="error-text">{errors.firstName}</p>
            )}
          </div>
          <div className="input-wrapper">
            <label>Last Name</label>
            <input
              onChange={changeHandler}
              name="lastName"
              value={data.lastName}
            />
            {errors.lastName && <p className="error-text">{errors.lastName}</p>}
          </div>
          <div className="input-wrapper">
            <label>Gender</label>
            <SelectField
              name="gender"
              onChange={selectDropdownChangeHandler}
              value={data.gender}
              isMulti={false}
              options={[
                {
                  value: "Male",
                  label: "Male",
                },
                {
                  value: "Female",
                  label: "Female",
                },
                {
                  value: "Not Specified",
                  label: "Not Specified",
                },
              ]}
            />
            {errors.gender && <p className="error-text">{errors.gender}</p>}
          </div>
          <button onClick={submitHandler} className="primary">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  adminDetails: state.auth.data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
      userDetailsRequest: actions.auth.userDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
