import axios from "../utils/axios";

//AUTH
export const login = (data) => axios("admins/auth/login", "POST", data);
export const getAdminDetails = () => axios("admins/auth/profile", "GET");
export const updateAdminDetails = (data) => axios("auth/profile", "PUT", data);
export const updateAdminImage = (data) =>
  axios("auth/profile/image", "PATCH", data);

export const updateAdminPassword = (data) =>
  axios("auth/profile/change-password", "PATCH", data);

//USERS
export const getUsers = (search) => axios(`users${search}`, "GET");
export const activateDeactivateUser = (id, data) =>
  axios(`users/${id}`, "PATCH", data);

// DOCUMENTS
export const uploadSingleDocument = (file) =>
  axios("documents/single", "POST", file, true);

// SALONS
export const getSalons = () => axios("salons", "GET");
export const updateSalon = (id, data) =>
  axios(`salons/${id}/images`, "PATCH", data);

//ADMINS
export const getAdmins = (search) => axios(`admins${search}`, "GET");
export const getPermissions = () => axios("admins/permissions", "GET");
export const createAdmin = (data) => axios("admins", "POST", data);
export const updateAdmin = (id, data) => axios(`admins/${id}`, "PUT", data);
export const activateDeactivateAdmin = (id, data) =>
  axios(`admins/${id}`, "PATCH", data);
// export const changePassword = (id, data) =>
//   axios(`admins/${id}/password`, "PATCH", data);

//PRODUCT PROMOCODES
export const getProductPromocodes = (search) =>
  axios(`product-promocodes${search}`, "GET");
export const createProductPromocode = (data) =>
  axios("product-promocodes", "POST", data);
export const updateProductPromocode = (id, data) =>
  axios(`product-promocodes/${id}`, "PUT", data);
export const activateDeactivateProductPromocode = (id, data) =>
  axios(`product-promocodes/${id}`, "PATCH", data);

//BANNERS
export const getBanners = (query) => axios(`banners${query}`, "GET");
export const createBanner = (data) => axios("banners", "POST", data);
export const updateBanner = (id, data) => axios(`banners/${id}`, "PUT", data);
export const makeBannerLive = (id) => axios(`banners/${id}/live`, "PATCH");
export const deleteBanner = (id) => axios(`banners/${id}`, "DELETE");

//FAQS
export const getFaqs = (query) => axios(`faqs${query}`, "GET");
export const createFaq = (data) => axios("faqs", "POST", data);
export const updateFaq = (id, data) => axios(`faqs/${id}`, "PUT", data);
export const activateDeactivateFaq = (id, data) =>
  axios(`faqs/${id}`, "PATCH", data);

//PRIVACY POLICIES
export const getPrivacyPolicies = (query) =>
  axios(`privacy-policies${query}`, "GET");
export const createPrivacyPolicy = (data) =>
  axios("privacy-policies", "POST", data);
export const updatePrivacyPolicy = (id, data) =>
  axios(`privacy-policies/${id}`, "PUT", data);
export const makePrivacyPolicyLive = (id) =>
  axios(`privacy-policies/${id}/live`, "PATCH");
export const deletePrivacyPolicy = (id) =>
  axios(`privacy-policies/${id}`, "DELETE");

//TERMS AND CONDITIONS
export const getTermsAndConditions = (query) =>
  axios(`terms-conditions${query}`, "GET");
export const createTermAndCondition = (data) =>
  axios("terms-conditions", "POST", data);
export const updateTermAndCondition = (id, data) =>
  axios(`terms-conditions/${id}`, "PUT", data);
export const makeTermAndConditionLive = (id) =>
  axios(`terms-conditions/${id}/live`, "PATCH");
export const deleteTermAndCondition = (id) =>
  axios(`terms-conditions/${id}`, "DELETE");

//TIPS AND TRICKS
export const getTipsAndTricks = (query) => axios(`tips-tricks${query}`, "GET");
export const createTipAndTrick = (data) => axios("tips-tricks", "POST", data);
export const updateTipAndTrick = (id, data) =>
  axios(`tips-tricks/${id}`, "PUT", data);
export const makeTipAndTrickLive = (id) =>
  axios(`tips-tricks/${id}/live`, "PATCH");
export const deleteTipAndTrick = (id) => axios(`tips-tricks/${id}`, "DELETE");

// SALONS
export const getAppointments = (search) =>
  axios(`salons/appointments${search}`, "GET");

// ORDERS
export const getOrders = (search) => axios(`orders${search}`, "GET");

//DASHBOARD
export const getDashboardData = (search) => axios(`dashboard${search}`, "GET");

//BLOG CATEGORIES
export const getBlogCategories = () => axios(`blogs/categories`, "GET");
export const getActiveBlogCategories = () =>
  axios(`blogs/categories/active`, "GET");
export const createBlogCategory = (data) =>
  axios("blogs/categories", "POST", data);
export const updateBlogCategory = (id, data) =>
  axios(`blogs/categories/${id}`, "PUT", data);
export const activateDeactivateBlogCategory = (id, data) =>
  axios(`blogs/categories/${id}`, "PATCH", data);

//PRODUCT CATEGORIES
export const getCollections = () => axios(`products/collections`, "GET");
export const getProductCategories = () => axios(`products/categories`, "GET");
export const getActiveProductCategories = () =>
  axios(`products/categories/active`, "GET");
export const createProductCategory = (data) =>
  axios("products/categories", "POST", data);
export const updateProductCategory = (id, data) =>
  axios(`products/categories/${id}`, "PUT", data);
export const activateDeactivateProductCategory = (id, data) =>
  axios(`products/categories/${id}`, "PATCH", data);

//BLOGS
export const getBlogs = (query) => axios(`blogs${query}`, "GET");
export const createBlog = (data) => axios("blogs", "POST", data);
export const updateBlog = (id, data) => axios(`blogs/${id}`, "PUT", data);
export const markBlogAsReviewed = (id) =>
  axios(`blogs/${id}/reviewed`, "PATCH");
export const markBlogAsPublished = (id) =>
  axios(`blogs/${id}/publish`, "PATCH");
export const deleteBlog = (id) => axios(`blogs/${id}`, "DELETE");

// api for forgot password
export const forgotPassword = (data) =>
  axios(`admins/auth/forget_password`, "POST", data);
//api to reset password
export const resetPassword = (data) =>
  axios(`admins/auth/reset_password`, "POST", data);

// api to get all users
export const getAllUser = (page, rowsPerPage) =>
  axios(`admins/user/user_details?page=${page}&limit=${rowsPerPage}`, "GET");

// api to fetch particular user data
export const getUserById = (data) =>
  axios(`admins/user/user_by_id?user_id=${data}`, "GET");

// api to get all permissions

export const getAllPermission = () =>
  axios(`admins/role/all_permissions`, "GET");

//api to create role

export const createRole = (data) =>
  axios(`admins/role/create_role`, "POST", data);

// api to create user with role

export const createUser = (data) =>
  axios(`admins/role/create_user`, "POST", data);

// api to get all roles

export const getAllRole = () => axios(`admins/role/all_roles`, "GET");

// api to get all users with role

export const getAllAdminUser = (page, rowsPerPage) =>
  axios(`admins/role/all_users?page=${page}&limit=${rowsPerPage}`, "GET");



// api to get and post privacy

export const getAllPolicy = (data) =>
  axios(`admins/privacy/create_privacy`, "POST", data);

export const getPolicy = () => axios(`admins/privacy/get_privacy`, "GET");

//api to create category
export const createCategory = (data) =>
  axios(`admins/user/create_category`, "POST", data);

//api to update category
export const updateCategory = (data) =>
  axios(`admins/user/update_category`, "PUT", data);

//api to create sub category
export const createSubCategory = (data, catid) =>
  axios(`admins/user/categories/${catid}/subcategories`, "POST", data);

//api to update sub categories
export const updateSubCategory = (data) =>
  axios("admins/user/update_subcategories", "PUT", data);

// api to update a role

export const updateRole = (data) =>
  axios(`admins/role/update_role`, "PUT", data);

// api to send all notifications

export const sendNotification = (data) =>
  axios(`admins/notification/create_notification`, "POST", data);

// api to update admin password

export const changeAdminPassword = (data) => axios(``, "POST", data);

//api to get all user all notifications
export const getAllNotificationUser = (page, limit) =>
  axios(`admins/notification/all_users?page=${page}&limit=${limit}`, "GET");

// api to delete a user and it is used in delete role
export const deleteAdminUser = (data) =>
  axios(`admins/role/delete_role`, "DELETE", data);

// api to update user role

export const updateAdminUser = (data) =>
  axios(`admins/role/update_user`, "PUT", data);

// api to delete Admin user

export const deactivateUser = (data) =>
  axios(`admins/role/delete_user`, "DELETE", data);

// api to delete a role
// export const deleteRole = (data)=>axios(`admins//role/delete_role`,"DELETE",data)

// api to change password

export const changePassword = (data) =>
  axios(`admins/auth/change_password`, "POST", data);

// api to deactive normal user

export const deactivateNormalUser = (data) =>
  axios(`admins/user/deactivate`, "PUT", data);


export const getAllCoach = (data) =>
  axios(`admins/user/get_coach_list?coachId=${data}`, "GET");

// api to get All Admin category and subcategory

export const getAllCategorySubcategory = (status)=>axios(`admins/user/admin_category_subcategory?status=${status}`,"GET")

// api to get all coach history 

export const getAllCoachHistory =()=>axios(`admins/user/get_assign_coach_list`,"GET")

// api to update current coach
export const updateCoach = (data)=>axios(`admins/user/update_coach`,"PUT",data)

// api to get all coaches drop-down
export const getCoaches =()=>axios(`admins/user/all_coach`,"GET")

// api to get chat by user id
export const getUserChats =(id,status)=>axios(`admins/user/get_userchat_by_id?userId=${id}&status=${status}`)

export const getSupportUser = () =>
  axios(`admins/user/get_support_user_list`, "GET");


// api to delete category 

export const deleteAdminCategory = (data)=>axios(`users/wardrobes/delete_categories`,"POST",data)


// api to delete admin sub-category

export const deleteAdminSubCategory  = (data)=>axios(`users/wardrobes/delete_subcategories`,"POST",data)