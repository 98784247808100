import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUserById } from "../../apis"; // Ensure this import matches your actual API import
import actions from "../../actions";
import "./UserDetails.css";
import CategoryPage from "../Categories/index";
import Subcategories from "../Subcategories";
import Planner from "../Planner";
import ChatHistory from "../ChatHistory";
import AiChat from "../AiChat";

import Pagination from "@mui/material/Pagination"; // MUI Pagination

const UserDetails = ({
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
  deactivateSidebarRequest,
}) => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("wardrobe");
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const itemsPerPage = 5; // Number of items per page

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        startLoadingRequest();
        const response = await getUserById(id); // Make the API call to get user details
        setUser(response.data.data); // Set the response data to the state
        deactivateSidebarRequest();
      } catch (error) {
        toast.error("Failed to fetch user details");
      } finally {
        stopLoadingRequest();
        setLoading(false);
      }
    };
    fetchUserDetails();
  }, [id, startLoadingRequest, stopLoadingRequest]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>User not found</div>;
  }

  // Function to handle pagination logic using slice
  const paginateItems = (items) => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return items.slice(indexOfFirstItem, indexOfLastItem);
  };

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const renderWardrobe = () => {
    const wardrobeItems = user?.waredrob || [];
    if (wardrobeItems.length === 0) {
      return <p>No wardrobe items found.</p>;
    }
    const currentWardrobeItems = paginateItems(wardrobeItems);
    const totalWardrobePages = Math.ceil(wardrobeItems.length / itemsPerPage);

    return (
      <>
        <div className="wardrobe-list">
          {currentWardrobeItems.map((item) => (
            <div key={item._id} className="wardrobe-item">
              <img
                src={item?.image}
                alt={item?.name}
                className="wardrobe-image"
              />
              <div className="wardrobe-details">
                <h3>{item?.name}</h3>
                <p>
                  <strong>Brand:</strong> {item?.brand}
                </p>
                <p>
                  {item?.price !== 0 && (
                    <strong>Price: Rs {item?.price}</strong>
                  )}
                </p>
                <p>
                  <strong>Size:</strong> {item?.size}
                </p>
                <p>
                  <strong>Primary Color:</strong> {item?.primaryColorName}
                </p>
                <p>
                  <strong>Secondary Color:</strong> {item?.secondaryColorName}
                </p>
                <p>
                  <strong>Description:</strong> {item?.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination-container">
          <Pagination
            count={totalWardrobePages} // Total number of pages
            page={currentPage} // Current page number
            onChange={handlePageChange} // Handle page change
            color="primary"
          />
        </div>
      </>
    );
  };

  const renderOutfit = () => {
    const outfitItems = user?.outfits || [];
    if (outfitItems.length === 0) {
      return <p>No outfits found.</p>;
    }
    const currentOutfitItems = paginateItems(outfitItems);
    const totalOutfitPages = Math.ceil(outfitItems.length / itemsPerPage);

    return (
      <>
        <div className="wardrobe-list">
          {currentOutfitItems.map((item) => (
            <div key={item._id} className="wardrobe-item">
              <img
                src={item?.image}
                alt={item?.name}
                className="wardrobe-image"
              />
              <div className="wardrobe-details">
                <h3>{item?.name}</h3>
                <p>
                  <strong>Brand:</strong> {item?.brand}
                </p>
                <p>
                  <strong>Price:</strong> ${item?.price}
                </p>
                <p>
                  <strong>Size:</strong> {item?.size}
                </p>
                <p>
                  <strong>Primary Color:</strong> {item?.primaryColorName}
                </p>
                <p>
                  <strong>Secondary Color:</strong> {item?.secondaryColorName}
                </p>
                <p>
                  <strong>Description:</strong> {item?.description}
                </p>
                <p>
                  <strong>Tags:</strong>{" "}
                  {item?.tags?.map((tag) => (
                    <span key={tag}>{tag}</span>
                  ))}
                </p>
                <p>
                  <strong>Instructions:</strong>{" "}
                  {item?.instructions?.map((instruction) => (
                    <span key={instruction}>{instruction}</span>
                  ))}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination-container">
          <Pagination
            count={totalOutfitPages} // Total number of pages
            page={currentPage} // Current page number
            onChange={handlePageChange} // Handle page change
            color="primary"
          />
        </div>
      </>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "wardrobe":
        return renderWardrobe();
      case "outfits":
        return renderOutfit();
      case "planner":
        return <Planner />;
      case "Category":
        return <CategoryPage />;
      case "SubCategory":
        return <Subcategories />;
      case "Moodboard":
        return <p>Moodboard</p>;
      case "ChatHistory":
        return <ChatHistory />;
      case "Ai Chat":
        return <AiChat />;
      default:
        return <p>Unknown tab.</p>;
    }
  };

  return (
    <div className="user-details-container">
      <div className="user-details-left">
        <div className="user-card">
          <div className="user-image">
            <img src={user.image || "/path/to/default-image.png"} alt="User" />
          </div>
          <h2>{`${user.firstName} ${user.lastName}`}</h2>
          <div className="user-details">
            <p>
              <strong>Username:</strong> {user.username || "N/A"}
            </p>
            <p>
              <strong>Email:</strong> {user.email}
            </p>
            <p>
              <strong>Status:</strong> {user.isActive ? "true" : "false"}
            </p>
            <p>
              <strong>Style Preference:</strong>{" "}
              {user.stylePreference || "User"}
            </p>
            <p>
              <strong>Role:</strong> {user.role || "N/A"}
            </p>
            <p>
              <strong>Date of Birth:</strong> {user.dob}
            </p>
            <p>
              <strong>Contact:</strong> {user.phoneNumber || "N/A"}
            </p>
            <p>
              <strong>Language:</strong> {user.language || "N/A"}
            </p>
            <p>
              <strong>Home Port:</strong> {user.country || "N/A"}
            </p>
          </div>
        </div>
      </div>

      <div className="user-details-right">
        <div className="tabs">
          <button
            onClick={() => setActiveTab("wardrobe")}
            className={activeTab === "wardrobe" ? "active" : ""}
          >
            Wardrobe
          </button>
          <button
            onClick={() => setActiveTab("outfits")}
            className={activeTab === "outfits" ? "active" : ""}
          >
            Outfits
          </button>
          <button
            onClick={() => setActiveTab("planner")}
            className={activeTab === "planner" ? "active" : ""}
          >
            Planner
          </button>
          <button
            onClick={() => setActiveTab("Category")}
            className={activeTab === "Category" ? "active" : ""}
          >
            Category
          </button>
          <button
            onClick={() => setActiveTab("SubCategory")}
            className={activeTab === "SubCategory" ? "active" : ""}
          >
            SubCategory
          </button>
          <button
            onClick={() => setActiveTab("Moodboard")}
            className={activeTab === "Moodboard" ? "active" : ""}
          >
            Moodboard
          </button>
          <button
            onClick={() => setActiveTab("ChatHistory")}
            className={activeTab === "ChatHistory" ? "active" : ""}
          >
            Chat History
          </button>
          <button
            onClick={() => setActiveTab("Ai Chat")}
            className={activeTab === "Ai Chat" ? "active" : ""}
          >
            Ai Chat
          </button>
        </div>
        {renderTabContent()}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
      deactivateSidebarRequest: actions.sidebar.deactivateSidebar,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(UserDetails);
