// // import React, { useCallback, useEffect, useState } from "react";
// // import {
// //   Box,
// //   Container,
// //   TextField,
// //   Button,
// //   Table,
// //   TableBody,
// //   TableCell,
// //   TableContainer,
// //   TableHead,
// //   TableRow,
// //   Paper,
// //   Typography,
// //   Checkbox,
// //   TablePagination,
// // } from "@mui/material";
// // import { bindActionCreators } from "redux";
// // import { connect } from "react-redux";
// // import actions from "../../actions";
// // import { getAllNotificationUser, sendNotification } from "../../apis";
// // import { toast } from "react-toastify";

// // const NotificationPage = ({ stopLoadingRequest }) => {
// //   const [selectedUsers, setSelectedUsers] = useState([]);
// //   const [title, setTitle] = useState("");
// //   const [message, setMessage] = useState("");
// //   const [userLists, setUserLists] = useState([]);
// //   const [filteredUserLists, setFilteredUserLists] = useState([]); // For filtered users
// //   const [searchQuery, setSearchQuery] = useState(""); // For search input
// //   const [page, setPage] = useState(0);
// //   const [rowsPerPage, setRowsPerPage] = useState(10);
// //   const [totalUsers, setTotalUsers] = useState(0);

// //   const fetchAllUser = useCallback(async (page, rowsPerPage) => {
// //     try {
// //       const response = await getAllNotificationUser(page + 1, rowsPerPage); // Adjust page to be 1-based index
// //       const users = response?.data?.data?.users || [];
// //       setUserLists(users);
// //       setFilteredUserLists(users); // Initialize filtered list with all users
// //       setTotalUsers(response?.data?.data?.totalUsers || 0);
// //     } catch (error) {
// //       console.log(error);
// //     }
// //   }, []);

// //   const handleSelectUser = (userId) => {
// //     setSelectedUsers((prev) =>
// //       prev.includes(userId)
// //         ? prev.filter((id) => id !== userId)
// //         : [...prev, userId]
// //     );
// //   };

// //   const handleSelectAllUsers = (event) => {
// //     if (event.target.checked) {
// //       const allUserIds = filteredUserLists.map((user) => user._id);
// //       setSelectedUsers(allUserIds);
// //     } else {
// //       setSelectedUsers([]);
// //     }
// //   };

// //   const handleChangePage = (event, newPage) => {
// //     setPage(newPage);
// //     fetchAllUser(newPage, rowsPerPage);
// //   };

// //   const handleChangeRowsPerPage = (event) => {
// //     const newRowsPerPage = parseInt(event.target.value, 10);
// //     setRowsPerPage(newRowsPerPage);
// //     setPage(0);
// //     fetchAllUser(0, newRowsPerPage);
// //   };

// //   const handleSendNotification = async () => {
// //     if (title && message && selectedUsers.length > 0) {
// //       const selectedUserEmails = filteredUserLists
// //         .filter((user) => selectedUsers.includes(user._id))
// //         .map((user) => user.email);

// //       const notificationData = {
// //         email: selectedUserEmails,
// //         title,
// //         body: message,
// //       };

// //       try {
// //         const apiResponse = await sendNotification(notificationData);
// //         toast.success(apiResponse?.data?.message);
// //       } catch (error) {
// //         toast.error(error.message);
// //       }
// //     } else {
// //       toast.error("Please fill all fields");
// //     }
// //   };

// //   const handleSearch = (event) => {
// //     const query = event.target.value.toLowerCase();
// //     setSearchQuery(query);

// //     const filteredUsers = userLists.filter((user) => {
// //       const fullName = user.firstName
// //         ? `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`
// //         : user.email.toLowerCase();
// //       return fullName.includes(query);
// //     });

// //     setFilteredUserLists(filteredUsers);
// //   };

// //   useEffect(() => {
// //     fetchAllUser(page, rowsPerPage);
// //     stopLoadingRequest();
// //   }, [fetchAllUser, stopLoadingRequest, page, rowsPerPage]);

// //   return (
// //     <div className="section" style={{ display: "flex" }}>
// //       <Container
// //         maxWidth="lg"
// //         sx={{ display: "flex", flexDirection: "column" }}
// //       >
// //         <Box sx={{ width: "30%", marginBottom: 2 }}>
// //           <TextField
// //             label="Search Users"
// //             variant="outlined"
// //             fullWidth
// //             value={searchQuery}
// //             onChange={handleSearch}
// //             margin="normal"
// //           />
// //         </Box>
// //         <Box sx={{ display: "flex" }}>
// //           <Box sx={{ width: "30%", marginRight: 4 }}>
// //             <Typography variant="h5" gutterBottom>
// //               Users
// //             </Typography>
// //             <TableContainer component={Paper}>
// //               <Table>
// //                 <TableHead>
// //                   <TableRow>
// //                     <TableCell padding="checkbox">
// //                       <Checkbox
// //                         indeterminate={
// //                           selectedUsers.length > 0 &&
// //                           selectedUsers.length < filteredUserLists.length
// //                         }
// //                         checked={
// //                           selectedUsers.length === filteredUserLists.length
// //                         }
// //                         onChange={handleSelectAllUsers}
// //                       />
// //                     </TableCell>
// //                     <TableCell>User</TableCell>
// //                   </TableRow>
// //                 </TableHead>
// //                 <TableBody>
// //                   {filteredUserLists?.map((user) => (
// //                     <TableRow key={user._id}>
// //                       <TableCell padding="checkbox">
// //                         <Checkbox
// //                           checked={selectedUsers.includes(user._id)}
// //                           onChange={() => handleSelectUser(user._id)}
// //                         />
// //                       </TableCell>
// //                       <TableCell>
// //                         {user.firstName
// //                           ? `${user.firstName} ${user.lastName}`
// //                           : user.email}
// //                       </TableCell>
// //                     </TableRow>
// //                   ))}
// //                 </TableBody>
// //               </Table>
// //               <TablePagination
// //                 rowsPerPageOptions={[5, 10, 25]}
// //                 component="div"
// //                 count={totalUsers}
// //                 rowsPerPage={rowsPerPage}
// //                 page={page}
// //                 onPageChange={handleChangePage}
// //                 onRowsPerPageChange={handleChangeRowsPerPage}
// //               />
// //             </TableContainer>
// //           </Box>
// //           <Box sx={{ width: "50%" }}>
// //             <Typography variant="h5" gutterBottom>
// //               Send Notification
// //             </Typography>
// //             <TextField
// //               label="Title"
// //               variant="outlined"
// //               fullWidth
// //               value={title}
// //               onChange={(e) => setTitle(e.target.value)}
// //               margin="normal"
// //             />
// //             <TextField
// //               label="Message"
// //               variant="outlined"
// //               fullWidth
// //               multiline
// //               rows={4}
// //               value={message}
// //               onChange={(e) => setMessage(e.target.value)}
// //               margin="normal"
// //             />
// //             <Button
// //               variant="contained"
// //               className="add"
// //               onClick={handleSendNotification}
// //               fullWidth
// //               sx={{ marginTop: 2 }}
// //             >
// //               Send Notification
// //             </Button>
// //           </Box>
// //         </Box>
// //       </Container>
// //     </div>
// //   );
// // };

// // const mapDispatchToProps = (dispatch) =>
// //   bindActionCreators(
// //     {
// //       logoutRequest: actions.auth.logout,
// //       stopLoadingRequest: actions.loader.stopLoading,
// //       startLoadingRequest: actions.loader.startLoading,
// //     },
// //     dispatch
// //   );

// // export default connect(null, mapDispatchToProps)(NotificationPage);

// // import React, { useCallback, useEffect, useState } from "react";
// // import {
// //   Box,
// //   Container,
// //   TextField,
// //   Button,
// //   Table,
// //   TableBody,
// //   TableCell,
// //   TableContainer,
// //   TableHead,
// //   TableRow,
// //   Paper,
// //   Typography,
// //   Checkbox,
// //   TablePagination,
// //   Grid,
// // } from "@mui/material";
// // import { bindActionCreators } from "redux";
// // import { connect } from "react-redux";
// // import actions from "../../actions";
// // import { getAllNotificationUser, sendNotification } from "../../apis";
// // import { toast } from "react-toastify";

// // const NotificationPage = ({ stopLoadingRequest }) => {
// //   const [selectedUsers, setSelectedUsers] = useState([]);
// //   const [title, setTitle] = useState("");
// //   const [message, setMessage] = useState("");
// //   const [userLists, setUserLists] = useState([]);
// //   const [filteredUserLists, setFilteredUserLists] = useState([]); // For filtered users
// //   const [searchQuery, setSearchQuery] = useState(""); // For search input
// //   const [page, setPage] = useState(0);
// //   const [rowsPerPage, setRowsPerPage] = useState(10);
// //   const [totalUsers, setTotalUsers] = useState(0);

// //   const fetchAllUser = useCallback(async (page, rowsPerPage) => {
// //     try {
// //       const response = await getAllNotificationUser(page + 1, rowsPerPage); // Adjust page to be 1-based index
// //       const users = response?.data?.data?.users || [];
// //       setUserLists(users);
// //       setFilteredUserLists(users); // Initialize filtered list with all users
// //       setTotalUsers(response?.data?.data?.totalUsers || 0);
// //     } catch (error) {
// //       console.log(error);
// //     }
// //   }, []);

// //   const handleSelectUser = (userId) => {
// //     setSelectedUsers((prev) =>
// //       prev.includes(userId)
// //         ? prev.filter((id) => id !== userId)
// //         : [...prev, userId]
// //     );
// //   };

// //   const handleSelectAllUsers = (event) => {
// //     if (event.target.checked) {
// //       const allUserIds = filteredUserLists.map((user) => user._id);
// //       setSelectedUsers(allUserIds);
// //     } else {
// //       setSelectedUsers([]);
// //     }
// //   };

// //   const handleChangePage = (event, newPage) => {
// //     setPage(newPage);
// //     fetchAllUser(newPage, rowsPerPage);
// //   };

// //   const handleChangeRowsPerPage = (event) => {
// //     const newRowsPerPage = parseInt(event.target.value, 10);
// //     setRowsPerPage(newRowsPerPage);
// //     setPage(0);
// //     fetchAllUser(0, newRowsPerPage);
// //   };

// //   const handleSendNotification = async () => {
// //     if (title && message && selectedUsers.length > 0) {
// //       const selectedUserEmails = filteredUserLists
// //         .filter((user) => selectedUsers.includes(user._id))
// //         .map((user) => user.email);

// //       const notificationData = {
// //         email: selectedUserEmails,
// //         title,
// //         body: message,
// //       };

// //       try {
// //         const apiResponse = await sendNotification(notificationData);
// //         toast.success(apiResponse?.data?.message);
// //       } catch (error) {
// //         toast.error(error.message);
// //       }
// //     } else {
// //       toast.error("Please fill all fields");
// //     }
// //   };

// //   const handleSearch = (event) => {
// //     const query = event.target.value.toLowerCase();
// //     setSearchQuery(query);

// //     const filteredUsers = userLists.filter((user) => {
// //       const fullName = user.firstName
// //         ? `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`
// //         : user.email.toLowerCase();
// //       return fullName.includes(query);
// //     });

// //     setFilteredUserLists(filteredUsers);
// //   };

// //   useEffect(() => {
// //     fetchAllUser(page, rowsPerPage);
// //     stopLoadingRequest();
// //   }, [fetchAllUser, stopLoadingRequest, page, rowsPerPage]);

// //   return (
// //     <Container
// //       maxWidth="lg"
// //       sx={{ marginLeft: { xs: "0", md: "240px" }, padding: 2 }}
// //       className="section"
// //     >
// //       <TextField
// //         label="Search Users"
// //         variant="outlined"
// //         fullWidth
// //         value={searchQuery}
// //         onChange={handleSearch}
// //         margin="normal"
// //         sx={{ marginBottom: 4 }}
// //       />
// //       <Grid container spacing={2}>
// //         <Grid item xs={12} md={4}>
// //           <Typography variant="h5" gutterBottom>
// //             Users
// //           </Typography>
// //           <TableContainer component={Paper} sx={{ marginTop: "26px" }}>
// //             <Table>
// //               <TableHead>
// //                 <TableRow>
// //                   <TableCell padding="checkbox">
// //                     <Checkbox
// //                       indeterminate={
// //                         selectedUsers.length > 0 &&
// //                         selectedUsers.length < filteredUserLists.length
// //                       }
// //                       checked={
// //                         selectedUsers.length === filteredUserLists.length
// //                       }
// //                       onChange={handleSelectAllUsers}
// //                     />
// //                   </TableCell>
// //                   <TableCell>User</TableCell>
// //                 </TableRow>
// //               </TableHead>
// //               <TableBody>
// //                 {filteredUserLists?.map((user) => (
// //                   <TableRow key={user._id}>
// //                     <TableCell padding="checkbox">
// //                       <Checkbox
// //                         checked={selectedUsers.includes(user._id)}
// //                         onChange={() => handleSelectUser(user._id)}
// //                       />
// //                     </TableCell>
// //                     <TableCell>
// //                       {user.firstName
// //                         ? `${user.firstName} ${user.lastName}`
// //                         : user.email}
// //                     </TableCell>
// //                   </TableRow>
// //                 ))}
// //               </TableBody>
// //             </Table>
// //             <TablePagination
// //               rowsPerPageOptions={[5, 10, 25]}
// //               component="div"
// //               count={totalUsers}
// //               rowsPerPage={rowsPerPage}
// //               page={page}
// //               onPageChange={handleChangePage}
// //               onRowsPerPageChange={handleChangeRowsPerPage}
// //             />
// //           </TableContainer>
// //         </Grid>
// //         <Grid item xs={12} md={8}>
// //           <Typography variant="h5" gutterBottom>
// //             Send Notification
// //           </Typography>
// //           <TextField
// //             label="Title"
// //             variant="outlined"
// //             fullWidth
// //             value={title}
// //             onChange={(e) => setTitle(e.target.value)}
// //             margin="normal"
// //           />
// //           <TextField
// //             label="Message"
// //             variant="outlined"
// //             fullWidth
// //             multiline
// //             rows={4}
// //             value={message}
// //             onChange={(e) => setMessage(e.target.value)}
// //             margin="normal"
// //           />
// //           <Button
// //             variant="contained"
// //             className="add"
// //             onClick={handleSendNotification}
// //             fullWidth
// //             sx={{ marginTop: 2 }}
// //           >
// //             Send Notification
// //           </Button>
// //         </Grid>
// //       </Grid>
// //     </Container>
// //   );
// // };

// // const mapDispatchToProps = (dispatch) =>
// //   bindActionCreators(
// //     {
// //       logoutRequest: actions.auth.logout,
// //       stopLoadingRequest: actions.loader.stopLoading,
// //       startLoadingRequest: actions.loader.startLoading,
// //     },
// //     dispatch
// //   );

// // export default connect(null, mapDispatchToProps)(NotificationPage);

// import React, { useCallback, useEffect, useState } from "react";
// import {
//   Box,
//   Container,
//   TextField,
//   Button,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Typography,
//   Checkbox,
//   TablePagination,
//   Grid,
// } from "@mui/material";
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import actions from "../../actions";
// import { getAllNotificationUser, sendNotification } from "../../apis";
// import { toast } from "react-toastify";
// import "./SendNotification.css";
// const NotificationPage = ({ stopLoadingRequest }) => {
//   const [selectedUsers, setSelectedUsers] = useState([]);
//   const [title, setTitle] = useState("");
//   const [message, setMessage] = useState("");
//   const [userLists, setUserLists] = useState([]);
//   const [filteredUserLists, setFilteredUserLists] = useState([]); // For filtered users
//   const [searchQuery, setSearchQuery] = useState(""); // For search input
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [totalUsers, setTotalUsers] = useState(0);

//   const fetchAllUser = useCallback(async (page, rowsPerPage) => {
//     try {
//       const response = await getAllNotificationUser(page + 1, rowsPerPage); // Adjust page to be 1-based index
//       const users = response?.data?.data?.users || [];
//       setUserLists(users);
//       setFilteredUserLists(users); // Initialize filtered list with all users
//       setTotalUsers(response?.data?.data?.totalUsers || 0);
//     } catch (error) {
//       console.log(error);
//     }
//   }, []);

//   const handleSelectUser = (userId) => {
//     setSelectedUsers((prev) =>
//       prev.includes(userId)
//         ? prev.filter((id) => id !== userId)
//         : [...prev, userId]
//     );
//   };

//   const handleSelectAllUsers = (event) => {
//     if (event.target.checked) {
//       if (filteredUserLists.length > 0) {
//         const allUserIds = filteredUserLists.map((user) => user._id);
//         setSelectedUsers(allUserIds);
//       } else {
//         setSelectedUsers(["no-users-found"]);
//       }
//     } else {
//       setSelectedUsers([]);
//     }
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage + 1);
//     fetchAllUser(newPage, rowsPerPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     const newRowsPerPage = parseInt(event.target.value, 10);
//     setRowsPerPage(newRowsPerPage);
//     setPage(0);
//     fetchAllUser(0, newRowsPerPage);
//   };

//   const handleSendNotification = async () => {
//     if (title && message && selectedUsers.length > 0) {
//       const selectedUserEmails = filteredUserLists
//         .filter((user) => selectedUsers.includes(user._id))
//         .map((user) => user.email);

//       const notificationData = {
//         email: selectedUserEmails,
//         title,
//         body: message,
//       };

//       try {
//         const apiResponse = await sendNotification(notificationData);
//         toast.success(apiResponse?.data?.message);
//       } catch (error) {
//         toast.error(error.message);
//       }
//     } else {
//       toast.error("Please fill all fields");
//     }
//   };

//   const handleSearch = (event) => {
//     const query = event.target.value.toLowerCase();
//     setSearchQuery(query);

//     const filteredUsers = userLists.filter((user) => {
//       const fullName = user.firstName
//         ? `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`
//         : user.email.toLowerCase();
//       return fullName.includes(query);
//     });

//     setFilteredUserLists(filteredUsers);
//     // If no users match the search, reset the selected users
//     if (filteredUsers.length === 0) {
//       setSelectedUsers(["no-users-found"]);
//     } else {
//       setSelectedUsers([]);
//     }
//   };

//   useEffect(() => {
//     fetchAllUser(page, rowsPerPage);
//     stopLoadingRequest();
//   }, [fetchAllUser, stopLoadingRequest, page, rowsPerPage]);

//   return (
//     <Container
//       maxWidth="md"
//       sx={{
//         // marginLeft: { xs: "0", md: "240px" },
//         padding: 2,
//         marginTop: 10,
//       }}
//       className="notification-container"
//     >
//       <TextField
//         label="Search Users"
//         variant="outlined"
//         fullWidth
//         value={searchQuery}
//         onChange={handleSearch}
//         margin="normal"
//         sx={{
//           marginBottom: 2,
//           maxWidth: "100%",
//         }}
//       />
//       <Grid container spacing={2}>
//         <Grid item xs={12} md={4}>
//           <Typography variant="h5" gutterBottom>
//             Users
//           </Typography>
//           <TableContainer
//             component={Paper}
//             sx={{ marginTop: "26px", backgroundColor: "#FAF9F6" }}
//           >
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableCell padding="checkbox">
//                     <Checkbox
//                       indeterminate={
//                         selectedUsers.length > 0 &&
//                         selectedUsers.length < filteredUserLists.length
//                       }
//                       checked={
//                         filteredUserLists.length === 0
//                           ? selectedUsers?.includes("no-users-found")
//                           : selectedUsers?.length === filteredUserLists?.length
//                       }
//                       onChange={handleSelectAllUsers}
//                     />
//                   </TableCell>
//                   <TableCell>User</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {filteredUserLists?.length > 0 ? (
//                   filteredUserLists?.map((user) => (
//                     <TableRow key={user._id}>
//                       <TableCell padding="checkbox">
//                         <Checkbox
//                           checked={selectedUsers.includes(user._id)}
//                           onChange={() => handleSelectUser(user._id)}
//                         />
//                       </TableCell>
//                       <TableCell>
//                         {user.firstName
//                           ? `${user.firstName} ${user.lastName}`
//                           : user.email}
//                       </TableCell>
//                     </TableRow>
//                   ))
//                 ) : (
//                   <TableRow>
//                     <TableCell padding="checkbox">
//                       <Checkbox
//                         checked={selectedUsers.includes("no-users-found")}
//                         onChange={() =>
//                           setSelectedUsers((prev) =>
//                             prev.includes("no-users-found")
//                               ? []
//                               : ["no-users-found"]
//                           )
//                         }
//                       />
//                     </TableCell>
//                     <TableCell>
//                       <Typography variant="body1" align="center">
//                         No users found
//                       </Typography>
//                     </TableCell>
//                   </TableRow>
//                 )}
//               </TableBody>
//             </Table>
//             <TablePagination
//               rowsPerPageOptions={[5, 10, 25]}
//               component="div"
//               count={totalUsers}
//               rowsPerPage={rowsPerPage}
//               page={page}
//               onPageChange={handleChangePage}
//               onRowsPerPageChange={handleChangeRowsPerPage}
//             />
//           </TableContainer>
//         </Grid>
//         <Grid item xs={12} md={8}>
//           <Typography variant="h5" gutterBottom>
//             Send Notification
//           </Typography>
//           <TextField
//             label="Title"
//             variant="outlined"
//             fullWidth
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             margin="normal"
//             sx={{
//               maxWidth: "100%",
//             }}
//           />
//           <TextField
//             label="Message"
//             variant="outlined"
//             fullWidth
//             multiline
//             rows={4}
//             value={message}
//             onChange={(e) => setMessage(e.target.value)}
//             margin="normal"
//             sx={{
//               maxWidth: "100%",
//             }}
//           />
//           <Button
//             variant="contained"
//             className="add"
//             onClick={handleSendNotification}
//             fullWidth
//             sx={{
//               marginTop: 2,
//               maxWidth: "100%",
//             }}
//           >
//             Send Notification
//           </Button>
//         </Grid>
//       </Grid>
//     </Container>
//   );
// };

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       logoutRequest: actions.auth.logout,
//       stopLoadingRequest: actions.loader.stopLoading,
//       startLoadingRequest: actions.loader.startLoading,
//     },
//     dispatch
//   );

// export default connect(null, mapDispatchToProps)(NotificationPage);

import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Container,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Checkbox,
  TablePagination,
  Grid,
} from "@mui/material";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import { getAllNotificationUser, sendNotification } from "../../apis";
import { toast } from "react-toastify";
import "./SendNotification.css";

const NotificationPage = ({ stopLoadingRequest }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [userLists, setUserLists] = useState([]);
  const [filteredUserLists, setFilteredUserLists] = useState([]); // For filtered users
  const [searchQuery, setSearchQuery] = useState(""); // For search input
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);

  // Fetch user data for the specific page and rows per page
  const fetchAllUser = useCallback(async (page, rowsPerPage) => {
    try {
      const response = await getAllNotificationUser(page + 1, rowsPerPage); // Adjust page to be 1-based index for the API
      const users = response?.data?.data?.users || [];
      setUserLists(users);
      setFilteredUserLists(users); // Initialize filtered list with all users
      setTotalUsers(response?.data?.data?.totalUsers || 0);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleSelectUser = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  // const handleSelectAllUsers = (event) => {
  //   console.log(event.target.checked);
  //   console.log(page);
  //   if (event.target.checked) {
  //     const allUserIds = filteredUserLists.map((user) => user._id);
  //     setSelectedUsers(allUserIds);
  //   } else {
  //     setSelectedUsers([]);
  //   }
  // };
  const handleSelectAllUsers = (event) => {
    if (event.target.checked) {
      const currentPageUserIds = filteredUserLists.map((user) => user._id);
      setSelectedUsers((prev) => [...prev, ...currentPageUserIds]);
    } else {
      const currentPageUserIds = filteredUserLists.map((user) => user._id);
      setSelectedUsers((prev) =>
        prev.filter((id) => !currentPageUserIds.includes(id))
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    fetchAllUser(newPage, rowsPerPage); // Fetch users for the new page
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page
    fetchAllUser(0, newRowsPerPage); // Fetch users for the new rows per page
  };

  const handleSendNotification = async () => {
    if (title && message && selectedUsers.length > 0) {
      const selectedUserEmails = filteredUserLists
        .filter((user) => selectedUsers.includes(user._id))
        .map((user) => user.email);

      const notificationData = {
        email: selectedUserEmails,
        title,
        body: message,
      };

      try {
        const apiResponse = await sendNotification(notificationData);
        toast.success(apiResponse?.data?.message);
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("Please fill all fields");
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredUsers = userLists.filter((user) => {
      const fullName = user.firstName
        ? `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`
        : user.email.toLowerCase();
      return fullName.includes(query);
    });

    setFilteredUserLists(filteredUsers);
    // Reset selected users if no users found
    if (filteredUsers.length === 0) {
      setSelectedUsers(["no-users-found"]);
    } else {
      setSelectedUsers([]);
    }
  };

  const isAllSelectedOnPage = filteredUserLists.every((user) =>
    selectedUsers.includes(user._id)
  );
  // Fetch users when the component mounts
  useEffect(() => {
    fetchAllUser(page, rowsPerPage);
    stopLoadingRequest();
  }, [fetchAllUser, stopLoadingRequest, page, rowsPerPage]);

  return (
    <Container
      maxWidth="md"
      sx={{
        padding: 2,
        marginTop: 10,
      }}
      className="notification-container"
    >
      <TextField
        label="Search Users"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={handleSearch}
        margin="normal"
        sx={{
          marginBottom: 2,
          maxWidth: "100%",
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography variant="h5" gutterBottom>
            Users
          </Typography>
          <TableContainer
            component={Paper}
            sx={{ marginTop: "26px", backgroundColor: "#FAF9F6" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedUsers.length > 0 &&
                        selectedUsers.length < filteredUserLists.length
                      }
                      // checked={
                      //   filteredUserLists.length === 0
                      //     ? selectedUsers?.includes("no-users-found")
                      //     : selectedUsers?.length === filteredUserLists?.length
                      // }
                      checked={isAllSelectedOnPage}
                      onChange={handleSelectAllUsers}
                    />
                  </TableCell>
                  <TableCell>User</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUserLists?.length > 0 ? (
                  filteredUserLists?.map((user) => (
                    <TableRow key={user._id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedUsers.includes(user._id)}
                          onChange={() => handleSelectUser(user._id)}
                        />
                      </TableCell>
                      <TableCell>
                        {user.firstName
                          ? `${user.firstName} ${user.lastName}`
                          : user.email}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers.includes("no-users-found")}
                        onChange={() =>
                          setSelectedUsers((prev) =>
                            prev.includes("no-users-found")
                              ? []
                              : ["no-users-found"]
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" align="center">
                        No users found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalUsers}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h5" gutterBottom>
            Send Notification
          </Typography>
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            margin="normal"
            sx={{
              maxWidth: "100%",
            }}
          />
          <TextField
            label="Message"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            margin="normal"
            sx={{
              maxWidth: "100%",
            }}
          />
          <Button
            variant="contained"
            className="add"
            onClick={handleSendNotification}
            fullWidth
            sx={{
              marginTop: 2,
              maxWidth: "100%",
            }}
          >
            Send Notification
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(NotificationPage);
