// import React, { useState, useEffect, useCallback } from "react";
// import { toast } from "react-toastify";
// import "./admincategory.css";
// import { getAllCategorySubcategory, createCategory } from "../../apis"; // Make sure to import createCategory API
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import actions from "../../actions";

// const AdminCategory = ({
//   stopLoadingRequest,
//   startLoadingRequest,
//   activateModal,
//   deactivateModal,
// }) => {
//   const [categories, setCategories] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [categoryName, setCategoryName] = useState("");
//   const [categoryImage, setCategoryImage] = useState(null);
//   const [stylePreference, setStylePreference] = useState(""); // Updated for dropdown
//   const [error, setError] = useState(""); // To display error message

//   const fetch = useCallback(async () => {
//     try {
//       const apiResponse = await getAllCategorySubcategory("subcategory");
//       if (apiResponse?.data?.data) {
//         setCategories(apiResponse?.data?.data);
//       }
//     } catch (error) {
//       console.error("Error fetching categories:", error);
//     }
//   }, []);

//   useEffect(() => {
//     stopLoadingRequest();
//     fetch();
//   }, [stopLoadingRequest, fetch]);

//   const CreateCategory = async (e) => {
//     e.preventDefault();

//     // Validate that both category name, image, and style preference are filled
//     if (!categoryName || !stylePreference) {
//       setError(
//         "All fields (category name, image, and style preference) are required"
//       );
//       return;
//     }

//     try {
//       setError(""); // Clear error before submitting

//       // Prepare form data without userId
//       const formData = new FormData();
//       formData.append("name", categoryName);
//       // formData.append("image", categoryImage); // Append the image
//       formData.append("stylePreference", stylePreference); // Append the style preference

//       console.log(formData);

//       // Send request to create category
//       const response = await createCategory(formData);
//       console.log(response);
//       if (response.status === 200) {
//         // Successfully created category
//         setIsModalOpen(false);
//         fetch(); // Refresh the categories list
//         setCategoryName(""); // Reset the form input
//         setCategoryImage(null);
//         setStylePreference(""); // Reset the style preference dropdown
//         toast.success("Category created successfully!");
//       } else {
//         console.error("Failed to create category");
//       }
//     } catch (error) {
//       console.error("Error creating category:", error);
//     }
//   };

//   const handleAddCategory = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     setError(""); // Reset error on close
//   };

//   return (
//     <div className="admin-category-page">
//       <button className="add-category-button" onClick={handleAddCategory}>
//         Add Category
//       </button>
//       <table className="category-table">
//         <thead>
//           <tr>
//             <th>#</th>
//             <th>Category Name</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {categories.map((category, index) => (
//             <tr key={category._id}>
//               <td>{index + 1}</td>
//               <td>{category?.name}</td>
//               <td>
//                 <div className="table-actions">
//                   <button onClick={handleAddCategory} className="edit-button">
//                     Edit
//                   </button>
//                   <button className="delete-button">Delete</button>
//                 </div>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {isModalOpen && (
//         <div className="modal">
//           <div className="modal-content">
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 marginBottom: "20px",
//               }}
//             >
//               <h2 style={{ margin: 0 }}>Add New Category</h2>
//               <span className="close" onClick={closeModal}>
//                 &times;
//               </span>
//             </div>
//             <form onSubmit={CreateCategory}>
//               <div className="form-group">
//                 <label htmlFor="categoryName">Category Name</label>
//                 <input
//                   type="text"
//                   id="categoryName"
//                   name="categoryName"
//                   value={categoryName}
//                   onChange={(e) => setCategoryName(e.target.value)} // Update state on input change
//                   required
//                 />
//               </div>
//               {/* <div className="form-group">
//                 <label htmlFor="categoryImage">Upload Image</label>
//                 <input
//                   type="file"
//                   id="categoryImage"
//                   name="categoryImage"
//                   accept="image/*"
//                   onChange={(e) => setCategoryImage(e.target.files[0])} // Update state with file
//                   required
//                 />
//               </div> */}
//               <div className="form-group">
//                 <label htmlFor="stylePreference">Style Preference</label>
//                 <select
//                   id="stylePreference"
//                   name="stylePreference"
//                   value={stylePreference}
//                   onChange={(e) => setStylePreference(e.target.value)} // Update state on select change
//                   required
//                 >
//                   <option value="" disabled>
//                     Select Style Preference
//                   </option>
//                   <option value="Men">Men</option>
//                   <option value="Women">Women</option>
//                 </select>
//               </div>
//               {error && <p style={{ color: "red" }}>{error}</p>}{" "}
//               {/* Display error message */}
//               <div className="modal-actions">
//                 <button
//                   type="button"
//                   className="cancel-button"
//                   onClick={closeModal}
//                 >
//                   Cancel
//                 </button>
//                 <button type="submit" className="submit-button">
//                   Save
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       logoutRequest: actions.auth.logout,
//       stopLoadingRequest: actions.loader.stopLoading,
//       startLoadingRequest: actions.loader.startLoading,
//       activateModal: actions.modal.activateModal,
//     },
//     dispatch
//   );

// export default connect(null, mapDispatchToProps)(AdminCategory);

import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import "./admincategory.css";
import {
  getAllCategorySubcategory,
  createCategory,
  updateSubCategory,
  deleteAdminSubCategory,
} from "../../apis"; // Import updateSubCategory API
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";

const AdminCategory = ({
  stopLoadingRequest,
  startLoadingRequest,
  activateModal,
  deactivateModal,
}) => {
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [stylePreference, setStylePreference] = useState(""); // Updated for dropdown
  const [selectedSubCategory, setSelectedSubCategory] = useState(null); // Store the selected subcategory for updates
  const [error, setError] = useState(""); // To display error message

  // Fetch all subcategories
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getAllCategorySubcategory("subcategory");
      if (apiResponse?.data?.data) {
        setCategories(apiResponse?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  }, []);

  useEffect(() => {
    stopLoadingRequest();
    fetch();
  }, [stopLoadingRequest, fetch]);

  // Handle creating or updating subcategory
  const handleSubmitSubCategory = async (e) => {
    e.preventDefault();

    // Validate that category name and style preference are filled
    if (!categoryName || !stylePreference) {
      setError("Both category name and style preference are required.");
      return;
    }

    try {
      setError(""); // Clear error before submitting

      // Prepare form data for creating or updating a subcategory
      const formData = {
        name: categoryName,
        stylePreference,
      };

      if (selectedSubCategory) {
        // Update existing subcategory (without userId)
        formData.subcategoryId = selectedSubCategory._id; // Use subcategoryId
        const response = await updateSubCategory(formData); // Update the subcategory

        if (response.status === 200) {
          toast.success("Subcategory updated successfully!");
        }
      } else {
        // Create a new subcategory
        const response = await createCategory(formData); // Send request to create subcategory
        if (response.status === 200) {
          toast.success("Subcategory created successfully!");
        }
      }

      // Close modal and reset form
      setIsModalOpen(false);
      setCategoryName("");
      setStylePreference("");
      setSelectedSubCategory(null);
      fetch(); // Refresh the list of categories
    } catch (error) {
      console.error("Error creating/updating subcategory:", error);
      toast.error("Failed to process the request.");
    }
  };

  // Open modal for adding or editing subcategory
  const handleAddCategory = (subcategory = null) => {
    if (subcategory) {
      setSelectedSubCategory(subcategory); // If editing, set the selected subcategory
      setCategoryName(subcategory.name);
      setStylePreference(subcategory.stylePreference);
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCategoryName("");
    setStylePreference("");
    setSelectedSubCategory(null);
    setError("");
  };

  const handleDelete = async (data) => {
    if (data) {
      console.log(data);
      const response = await deleteAdminSubCategory({
        subcategoryId: data?._id,
      });
      console.log(response);
      fetch();
    }
  };

  return (
    <div className="admin-category-page">
      <button
        className="add-category-button-new"
        onClick={() => handleAddCategory(null)}
      >
        Add Subcategory
      </button>
      <table className="category-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Subcategory Name</th>
            <th>Style Preference</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, index) => (
            <tr key={category._id}>
              <td>{index + 1}</td>
              <td>{category?.name}</td>
              <td>{category?.stylePreference}</td>
              <td>
                <div className="table-actions">
                  <button
                    onClick={() => handleAddCategory(category)}
                    className="edit-button-new"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      handleDelete(category);
                    }}
                    className="delete-button-new"
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <div
          style={{
            zIndex: "10000",
            position: "fixed", // Changed to fixed
            top: 0, // Covering the entire screen
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.4)", // Semi-transparent background
            backdropFilter: "blur(10px)", // Blur effect
          }}
        >
          <div className="modal-content">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <h2 style={{ margin: 0 }}>
                {selectedSubCategory
                  ? "Edit Subcategory"
                  : "Add New Subcategory"}
              </h2>
              <span className="close" onClick={closeModal}>
                &times;
              </span>
            </div>
            <form onSubmit={handleSubmitSubCategory}>
              <div className="form-group">
                <label htmlFor="categoryName">Subcategory Name</label>
                <input
                  type="text"
                  id="categoryName"
                  name="categoryName"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="stylePreference">Style Preference</label>
                <select
                  id="stylePreference"
                  name="stylePreference"
                  value={stylePreference}
                  onChange={(e) => setStylePreference(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select Style Preference
                  </option>
                  <option value="Men">Men</option>
                  <option value="Women">Women</option>
                </select>
              </div>
              {error && <p style={{ color: "red" }}>{error}</p>}
              <div className="modal-actions">
                <button
                  type="button"
                  className="cancel-button"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button type="submit" className="submit-button">
                  {selectedSubCategory ? "Update" : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
      activateModal: actions.modal.activateModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AdminCategory);
