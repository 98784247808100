import React, { useState } from "react";
import "./CategorySubCategory.css";


import SubcategoryPage from "../../pages/Subcategories";
import AdminCategory from "../AdminCategory";
import AdminSubcategory from "../AdminSubcategory";

function CategorySubcategory() {
  const [activeComponent, setActiveComponent] = useState(<AdminCategory />);

  const handleCategoryClick = () => {
    setActiveComponent(<AdminCategory />);
  };
  const handleSubCategoryClick = () => {
    setActiveComponent(<AdminSubcategory />);
  };
  return (
    <>
      <div className="category-subcategory-container">
        <div className="button-container">
          <button
            className="category-subcategory-btn"
            onClick={handleCategoryClick}
          >
            Category
          </button>
          <button
            className="category-subcategory-btn"
            onClick={handleSubCategoryClick}
          >
            Subcategory
          </button>
        </div>
        <div className="component-render">{activeComponent}</div>
      </div>
    </>
  );
}

export default CategorySubcategory;
