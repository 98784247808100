import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../../actions";
import styles from "./Chat.module.css"; // Assuming you create a CSS module file for styling
import { io } from "socket.io-client";
import { getAllCoach } from "../../apis";
import { SetMealSharp } from "@mui/icons-material";

const Chat = ({ startLoadingRequest, stopLoadingRequest }) => {
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [selectUser, setSelectUser] = useState(null);
  const [media, setMedia] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [socket, setSocket] = useState(null);
  const [chats, setChats] = useState([]);
  const [userid, setUserid] = useState(null);

  useEffect(() => {
    stopLoadingRequest();

    // Fetch user details from localStorage once and set userid
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      const user_id = JSON.parse(userDetails)._id;
      setUserid(user_id);
    }
  }, []);

  const handleSendMessage = () => {
    console.log("hit send", selectUser);
    console.log(message);
    const userDetails = localStorage.getItem("userDetails");

    if (userDetails) {
      console.log(userDetails, "11111");
      const user_id = JSON.parse(userDetails)._id;
      const senderId = user_id;
      const receiverId = selectUser.userId._id;
      const roomId = selectUser.roomId;

      // if (socket && selectedUser) {
      socket.emit("send_message", message, senderId, receiverId, roomId, media);
      setMessage("");

      // }
    }
  };

  const loadChat = (user) => {
    const userDetails = localStorage.getItem("userDetails");
    const user_id = JSON.parse(userDetails);
    // setUserid(user_id._id);
    console.log(user);
    const senderId = user_id?._id;
    const receiverId = user?.userId?._id;
    if (socket) {
      socket.emit("joinAdminRoom", senderId, receiverId);
      socket.emit("loadAdminChat", senderId, receiverId);
    }
  };

  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");
    const user_id = JSON.parse(userDetails);
    const fetchUser = async () => {
      const response = await getAllCoach(user_id._id);
      console.log(response);
      setUsers(response?.data?.data);
      console.log(response);
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const newSocket = io("wss://ponderiee-api.applore.in/", {
      extraHeaders: { "ngrok-skip-browser-warning": "any-value" },
    });

    newSocket.on("connect", () => {
      setSocket(newSocket);
    });

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");
    const user_id = JSON.parse(userDetails);
    console.log(user_id?._id, "2222");

    if (!socket) return;
    // socket.emit("loadAdminUsers", user_id?._id);

    // const handleLoadAdminUsers = (item) => {
    //   console.log(item);
    //   //   setUsers(item);
    // };

    const handleAdminMessageReceived = (item) => {
      console.log(item);
      setMessages((prevMessages) => [...prevMessages, item]);
    };

    const handleLoadAdminChat = (item) => {
      console.log(item);
      setMessages(item);
    };

    socket.on("message_received", handleAdminMessageReceived);
    socket.on("loadAdminChat", handleLoadAdminChat);

    return () => {
      socket.off("message_received", handleAdminMessageReceived);
      socket.off("loadAdminChat", handleLoadAdminChat);
    };
  }, [socket]);

  const renderOptions = (options) => {
    return (
      <div className={styles.optionsContainer}>
        {options.map((option, index) => (
          <button key={index} className={styles.optionButton}>
            {option}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="section chatContainer">
      <div className={styles.userList}>
        {/* Placeholder for users - replace with actual user data */}
        {users?.map((user, index) => (
          <div
            key={index}
            className={styles.userItem}
            onClick={() => {
              // setSelectUser(user?.userId);
              console.log(user);
              setSelectUser(user);
              loadChat(user);
            }}
          >
            {user?.userId?.firstName || "no name"}
          </div>
        ))}
      </div>
      <div className={styles.chatWindow}>
        {selectUser ? (
          <>
            <div className={styles.chatHeader}>
              Chat with {selectUser.firstName}
            </div>
            <div className={styles.chatMessages}>
              {messages.map((chat) => (
                <div key={chat._id}>
                  {
                    <div
                      className={
                        chat.senderId === userid
                          ? styles.adminMessage
                          : styles.userMessage
                      }
                    >
                      {chat.message}
                      {chat.senderId === userid &&
                        chat.options &&
                        chat.options.length > 0 &&
                        renderOptions(chat.options)}
                    </div>
                  }
                </div>
              ))}
            </div>
            <div className={styles.chatInput}>
              {/* Chat input box and send button */}
              <input
                onChange={(e) => {
                  setMessage(e.target.value); // Update the message state on change
                }}
                value={message} // Bind the input value to the message state
                type="text"
                placeholder="Type a message..."
              />
              <button onClick={handleSendMessage}>Send</button>
            </div>
          </>
        ) : (
          <div className={styles.noChatSelected}>
            Select a user to start chatting
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Chat);
