import React, { useEffect, useState, useCallback } from "react";
import { getUserById } from "../../apis";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import "./planner.css";
import Pagination from "@mui/material/Pagination"; // MUI Pagination

const Planner = ({ startLoadingRequest, stopLoadingRequest }) => {
  const [planners, setPlanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const fetchPlannerData = useCallback(async () => {
    try {
      startLoadingRequest();
      const response = await getUserById(id);
      const { planners } = response.data.data;
      setPlanners(planners || []);
      stopLoadingRequest();
      setLoading(false);
    } catch (error) {
      console.error("Error fetching planner data:", error);
      setPlanners([]);
      stopLoadingRequest();
      setLoading(false);
    }
  }, [startLoadingRequest, stopLoadingRequest, id]);

  useEffect(() => {
    fetchPlannerData();
  }, [fetchPlannerData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const plannersByDate = planners.reduce((acc, planner) => {
    const date = planner.date;
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(planner);
    return acc;
  }, {});

  return (
    <div className="planner-page">
      <h1>Planners</h1>
      {Object.keys(plannersByDate).length === 0 ? (
        <p className="no-items-message">No planners found</p>
      ) : (
        Object.keys(plannersByDate).map((date) => (
          <div key={date}>
            <h2 className="planner-date-heading">Date: {date}</h2>
            {plannersByDate[date].map((planner) => (
              <div className="planner-card" key={planner._id}>
                <h3 className="planner-title">
                  {planner.name || "Untitled Planner"}
                </h3>
                {planner.outfitId ? (
                  <div className="outfit-container">
                    <strong>Outfit:</strong>
                    <div className="outfit-item">
                      <img
                        src={planner.outfitId.image}
                        alt={planner.outfitId.name}
                        className="outfit-image"
                      />
                      <p className="outfit-name">{planner.outfitId.name}</p>
                    </div>
                  </div>
                ) : (
                  <p className="no-outfits-message">No outfits available</p>
                )}
                {planner.wardrobeId.length > 0 ? (
                  <div className="wardrobeP-container">
                    <strong>Wardrobe:</strong>
                    {planner.wardrobeId.map((item) => (
                      <div className="wardrobeP-item" key={item._id}>
                        <img
                          src={item.image}
                          alt={item.name}
                          className="wardrobeP-image"
                        />
                        <p className="wardrobeP-name">{item.name}</p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="no-outfits-message">
                    No wardrobe items available
                  </p>
                )}
              </div>
            ))}
            <hr className="divider-line" />
          </div>
        ))
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Planner);
